

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { BaseCouponItem, CouponInfoListItem } from '@xt/client/types/coupon'
import GiftCoupon from './GiftCoupon.vue'
import CouponItem from '@xt/www/pages/my/children/packs/views/coupon.vue'

@Observer
@Component({
  components: {
    GiftCoupon,
    CouponItem
  }
})
export default class OrderCouponGift extends Vue {
  @Prop({ required: true }) data: { failList: Array<BaseCouponItem>; successList: Array<BaseCouponItem> }
  @Prop({ default: false }) visible: boolean

  secondViewInfo: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  } = {
    list: [],
    coupon: null
  }

  couponStatus(item: BaseCouponItem) {
    return item.stockQty === 0 ? { text: '已发完', style: 'black' } : { text: '发放失败，已达领取上限', style: 'red' }
  }

  async readMessage() {
    await this.$store.$coupon.readOrderSuccessCouponGift(this.data.successList.map(data => data.couponCode))
  }

  async handleClose() {
    await this.readMessage()

    this.$emit('close')
  }

  async handleConfirm() {
    await this.handleClose()

    if (!this.$route.path.endsWith('/my/packs')) {
      this.$router.replace('/my/packs')
    }
  }

  @Emit('receive')
  handleReceive() {}

  @Emit('exchange')
  handleExchange() {}

  mounted() {}
}













































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Emoji from './emoji.vue'
import { AnswerItem } from '@xt/client/entities/gensee/types'

@Observer
@Component({
  components: {
    Emoji
  }
})
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean

  word = ''
  visibleEmoji = false
  selectionStart = 0
  isComposition = false

  get PageStore() {
    return this.$store.$genseeLive
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  @Ref('question-record')
  questionRecord: HTMLDivElement

  @Ref('input-box')
  inputBox: any

  answerHandler(question: AnswerItem) {
    if (question.mainQAownerId === '0' || question.mainQAownerId !== this.UserStore.UserInfo.id) {
      this.PageStore.setAnswerInfo(null)
      return
    }
    this.PageStore.setAnswerInfo({
      questionId: question.mainId,
      qaContent: question.answer,
      answerername: question.answerBy
    })
  }

  keyDownHandler(e: KeyboardEvent) {
    if (this.isComposition) return
    if (e.key === 'Enter') {
      if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
        this.word += '\n'
      } else {
        this.onSubmitQA()
      }
    }
  }

  compositionstartHandler() {
    this.isComposition = true
  }

  compositionendHandler() {
    this.isComposition = false
  }

  textFocusHandler() {
    window.addEventListener('keydown', this.keyDownHandler)
    window.addEventListener('compositionstart', this.compositionstartHandler)
    window.addEventListener('compositionend', this.compositionendHandler)
  }

  textBlurHandler() {
    const node = this.inputBox.$el as HTMLTextAreaElement
    this.selectionStart = typeof node.selectionStart === 'number' ? node.selectionStart : this.word.length
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('compositionstart', this.compositionstartHandler)
    window.removeEventListener('compositionend', this.compositionendHandler)
  }

  chooseHandler(emoji: string) {
    this.word = this.word.slice(0, this.selectionStart).concat(emoji).concat(this.word.slice(this.selectionStart))
    this.selectionStart += emoji.length
  }

  @Emit('close')
  onClose() {}

  async onSubmitQA() {
    const msg = await this.PageStore.submitQAMessage(this.word)
    if (msg) return this.$msg(msg)
    this.word = ''
    this.PageStore.setAnswerInfo(null)
  }

  onVisible(visible: boolean, type: 'emoji') {
    this.resetClickHandler()
    if (visible) {
      switch (type) {
        case 'emoji':
          this.visibleEmoji = visible
          break
      }
      this.recoverClickHandler()
    }
  }

  clickHandler(e: MouseEvent) {
    const node = e.target as HTMLElement
    const id = node.offsetParent?.id
    if (id && id.startsWith('live-operation-trigger')) return
    this.resetClickHandler()
  }

  resetClickHandler() {
    this.visibleEmoji = false
    document.removeEventListener('click', this.clickHandler)
  }

  recoverClickHandler() {
    document.addEventListener('click', this.clickHandler)
  }

  @Watch('visible')
  changeVisible(value: boolean) {
    const ob = new MutationObserver(list => {
      list.forEach(item => {
        ;(item.addedNodes[0] as HTMLDivElement)?.scrollIntoView({ block: 'end', behavior: 'smooth' })
      })
    })
    if (value) {
      ob.observe(this.questionRecord, { childList: true, attributes: false })
    } else {
      if (ob) {
        ob.disconnect()
      }
    }
  }

  created() {}
  mounted() {}
  destroyed() {}
}

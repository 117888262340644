




































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import ExchangeTabsComp from './views/tabs.vue'
import PackItemComp from './views/coupon.vue'
import HowToUse from './views/howToUse.vue'
import EmptyComp from '@xt/www/components/system/empty.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import { EnumCouponStatus } from '@xt/client/enums'

@Observer
@Component({
  scrollToTop: true,
  components: { ExchangeTabsComp, PackItemComp, EmptyComp, Teleport, HowToUse }
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$coupon.myPacks
  }

  get body() {
    return {
      couponType: this.couponType
    }
  }

  loading = true
  showHowToUseModal = false
  tabActiveKey = +lodash.get(this.$route.query, 'active', 0)
  couponType = +lodash.get(this.$route.query, 'active', this.tabActiveKey)

  async onTabChange(active: number) {
    if (this.tabActiveKey === active) return

    this.PageStore.onResetMyPackList()
    this.tabActiveKey = this.couponType = active
    this.init()
  }

  closeHowToUseModal() {
    this.showHowToUseModal = false
  }

  onReceive(data: { id: number; youzanDetail: any }) {
    const cloneList = [...this.toJS(this.PageStore.myPacksList)].map(v => {
      if (v.id === data.id) {
        lodash.set(v, 'wenfangMemberHeader', data.youzanDetail.avatar)
        lodash.set(v, 'wenfangMemberNickname', data.youzanDetail.userNickname)
        lodash.set(v, 'wenfangPhoneNum', data.youzanDetail.userPhone)
        lodash.set(v, 'wenfangReceived', true)
      }
      return v
    })
    this.PageStore.setMyPacksList(cloneList)
  }

  onExchange(id: number) {
    const cloneList = [...this.toJS(this.PageStore.myPacksList)].map(v => {
      if (v.id === id) {
        lodash.set(v, 'status', EnumCouponStatus.Used)
      }
      return v
    })
    this.PageStore.setMyPacksList(cloneList)
  }

  async init() {
    this.loading = true
    try {
      await this.PageStore.onGetMyPacks(this.couponType === 0 ? 0 : `${this.couponType}00`)
    } catch (error) {
    } finally {
      this.loading = false
    }
  }

  created() {
    this.init()
  }
}

























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { giftCache, giftExpiredTime } from '@xt/client/helpers'

@Observer
@Component
export default class CouponRefundNoticeModal extends Vue {
  @Prop() dataSource: any

  get PageStore() {
    return this.$store.$storeUser
  }

  onClose() {
    giftCache.set(`coupon-refund-notice-${this.dataSource.orderNo}`, 'expired', giftExpiredTime)
    this.PageStore.removeCouponRefundNotice(this.dataSource.couponId)
  }

  async onConfirm() {
    const resp = await this.PageStore.onCouponConfirmRefund(this.dataSource.couponId)
    if (resp) {
      this.PageStore.removeCouponRefundNotice(this.dataSource.couponId)
      this.PageStore.removeCouponRefundNoticeData(this.dataSource.couponId)
    }
  }
}

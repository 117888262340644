import { render, staticRenderFns } from "./headerNotice.vue?vue&type=template&id=18dc1482&scoped=true&"
import script from "./headerNotice.vue?vue&type=script&lang=ts&"
export * from "./headerNotice.vue?vue&type=script&lang=ts&"
import style0 from "./headerNotice.vue?vue&type=style&index=0&id=18dc1482&lang=less&scoped=true&"
import style1 from "./headerNotice.vue?vue&type=style&index=1&id=18dc1482&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18dc1482",
  null
  
)

export default component.exports






























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Footer from './footer.vue'
import { enterFullScreen } from '@xt/client/helpers/system'

@Observer
@Component({
  components: { Footer }
})
export default class LivePageView extends Vue {
  @Prop({ default: false }) toggle: boolean
  @Prop({ default: false }) fullscreen: boolean

  controlVisible = true
  controlDuration = 4000
  controlTimer: NodeJS.Timer | null = null

  get PageStore() {
    return this.$store.$genseeLive
  }

  @Ref('doc-widget-box')
  docWidgetBox: HTMLDivElement

  docBoxMoveHandler() {
    if (!this.PageStore.isFullScreen) return
    this.recoverControlTimer()
  }

  resetControlTimer() {
    this.controlVisible = false
    if (this.controlTimer) {
      clearTimeout(this.controlTimer)
      this.controlTimer = null
    }
  }

  recoverControlTimer() {
    this.controlVisible = true
    if (this.controlTimer) {
      clearTimeout(this.controlTimer)
      this.controlTimer = null
    }
    this.controlTimer = setTimeout(this.resetControlTimer, this.controlDuration)
  }

  fullscreenHandler() {
    const targetNode = document.querySelector('#doc-live-widget-box') as HTMLDivElement
    enterFullScreen(targetNode)
  }

  @Watch('fullscreen')
  changeFullscreen(value: boolean) {
    if (value) {
      this.recoverControlTimer()
    }
  }

  created() {}
  mounted() {}
  destroyed() {}
}

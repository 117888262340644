






































































































import { Component, Prop, Vue, Ref, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { FormModel } from 'ant-design-vue'

@Observer
@Component
export default class ExchangePopup extends Vue {
  @Prop({ default: '' }) title: string
  @Prop({ default: false }) visible: boolean

  isSubmiting = false

  get disabled() {
    return !this.$Regulars.mobilePhone.test(this.model.phone) || !this.model.confirmCode || this.model.confirmCode.length !== 4
  }

  @Ref('ruleForm')
  ruleForm: FormModel

  deadline = 60
  model = {
    phone: '',
    confirmCode: ''
  }
  isStartCountDown = false
  formRules = {
    phone: [
      {
        validator: async (rule, value, callback) => {
          if (value == '') {
            return callback(new Error('请输入手机号'))
          }
          if (!this.$Regulars.mobilePhone.test(value)) {
            return callback(new Error('请输入正确的手机号'))
          }
          return callback()
        },
        trigger: 'blur'
      }
    ],
    confirmCode: [
      {
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      },
      { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
    ]
  }

  numberInput(target) {
    target.value = target.value.replace(/[^\d]/g, '')
  }

  //发送验证码
  async onSendSms() {
    if (!this.model.phone) return
    this.ruleForm.validateField('phone', async error => {
      if (error) return this.$msg(error)

      this.$emit('sendSms', this.model.phone, () => {
        this.toggleCountDown(true)
      })
    })
  }

  submitForm() {
    this.ruleForm.validate((vaild, errList) => {
      if (vaild) {
        if (this.isSubmiting) return
        this.isSubmiting = true
        this.$emit('submit', this.model.phone, this.model.confirmCode, () => {
          this.isSubmiting = false
        })
      } else {
        let keys = lodash.keys(errList)
        let error = lodash.get(errList, [lodash.head(keys), 0, 'message'], '校验失败')
        return this.$msg(error)
      }
    })
  }

  toggleCountDown(loading: boolean = !this.isStartCountDown) {
    if (loading) {
      this.deadline = Date.now() + 1000 * 60
    }
    this.isStartCountDown = loading
  }

  reset() {
    this.model = {
      phone: '',
      confirmCode: ''
    }
    this.deadline = 60
    this.isStartCountDown = false
    this.isSubmiting = false
    this.ruleForm.resetFields()
  }

  @Emit('close')
  closeMadel() {
    this.reset()
  }

  @Watch('visible')
  changeVisible(visible: boolean) {
    if (!visible) {
      this.reset()
    }
  }

  created() {}
  mounted() {}
}

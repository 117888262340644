

























import { Observer } from 'mobx-vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { EnumCouponType, EnumPreferentialType } from '@xt/client/enums'
import { FormatCouponInfo, formatCouponInfo } from '@xt/client/utils/coupon'
import { CouponPackItem } from '@xt/client/types/coupon'
import CouponBtnComp from '@xt/www/components/coupon/CouponBtn.vue'

@Observer
@Component({
  components: { CouponBtnComp }
})
export default class PackItemComp extends Vue {
  @Prop() dataSource: CouponPackItem
  @Prop({ default: false }) isLast: boolean

  couponInfo: FormatCouponInfo | null = null

  get EnumPreferentialType() {
    return EnumPreferentialType
  }

  get isWenfangCoupon() {
    return this.dataSource.couponType === EnumCouponType.PencilStore
  }

  useDescHandler() {
    // @ts-ignore
    this.$refs['coupon-btn-comp']?.clickUseDescHandler()
  }

  @Emit('receive')
  onReceive() {}

  @Emit('exchange')
  onExchange() {}

  created() {
    const timeType = this.isWenfangCoupon && !this.dataSource.wenfangReceived ? 'activity' : 'coupon'
    this.couponInfo = formatCouponInfo(this.dataSource, timeType)
  }
}





































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { enterFullScreen, exitFullScreen } from '@xt/client/helpers/system'
import { LiveStatus } from '@xt/client/entities/gensee/live'

@Observer
@Component
export default class LivePageView extends Vue {
  @Prop({ default: true }) show: boolean

  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null
  visibleNetwork = false
  visibleVolume = false
  dragVolumeSlider = false

  get PageStore() {
    return this.$store.$genseeLive
  }

  get LiveStatus() {
    return LiveStatus
  }

  netSettingStyle() {
    const height = this.PageStore.netSettingList.length * 36 + 12 * 2
    return {
      top: this.$px2rem(-height - 15),
      height: this.$px2rem(height)
    }
  }

  volumeHandler() {
    if (this.PageStore.volume === 0) {
      this.PageStore.recoverVolumePlay()
    } else {
      this.PageStore.resetVolumePlay()
    }
  }

  volumeChangeHandler(value: number) {
    this.dragVolumeSlider = true
    this.PageStore.updateVolumeByDrag(value)
  }

  volumeAfterChangeHandler() {
    // 滑动滑块鼠标移出区域后  音量控件会被关掉
    setTimeout(() => {
      this.dragVolumeSlider = false
    }, 20)
  }

  @Emit('toggleScreen')
  toggleScreenHandler() {
    this.toggle = !this.toggle
    return this.toggle
  }

  refreshVideoHandler() {
    if (!(this.PageStore.liveStatus === LiveStatus.Playing)) return
    const index = this.PageStore.netSettingList.findIndex(item => item.selected)
    this.PageStore.changeNetworkChoice(this.PageStore.netSettingList[(index + 1) % this.PageStore.netSettingList.length].netName)
  }

  chooseNetworkHandler(netName: string) {
    this.PageStore.changeNetworkChoice(netName)
  }

  fullScreenHandler() {
    const targetNode = document.querySelector(!this.toggle ? '#video-live-widget-box' : '#doc-live-widget-box') as HTMLDivElement
    if (this.PageStore.isFullScreen) {
      exitFullScreen()
    } else {
      enterFullScreen(targetNode)
    }
  }

  fullscreenChangeHandler() {
    const ids = ['video-live-widget-box', 'doc-live-widget-box']
    this.PageStore.setFullScreen(!!ids.includes(document.fullscreenElement?.id))
  }

  created() {
    document.addEventListener('fullscreenchange', this.fullscreenChangeHandler)
  }
  mounted() {}
  destroyed() {
    document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler)
  }
}































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { BaseCouponItem } from '@xt/client/types/coupon'
import { formatCouponInfo, FormatCouponInfo } from '@xt/client/utils/coupon/index'
import { EnumCouponType, EnumPreferentialType } from '@xt/client/enums'
import CouponBtnComp from '@xt/www/components/coupon/CouponBtn.vue'

@Observer
@Component({
  components: {
    CouponBtnComp
  }
})
export default class GiftCoupon extends Vue {
  @Prop({ required: true }) data: BaseCouponItem
  @Prop({ required: true }) success: boolean
  @Prop() isLast: boolean
  formatCouponInfo: FormatCouponInfo

  get couponType(): 'FullReduction' | 'Discount' | 'Exchange' | 'Companion' {
    if (this.data.preferentialType === EnumPreferentialType.StudyBuff && this.data.couponType === EnumCouponType.Companion) {
      return 'Companion'
    }

    switch (this.data.preferentialType) {
      case EnumPreferentialType.FullReduction:
        return 'FullReduction'
      case EnumPreferentialType.Discount:
        return 'Discount'
      case EnumPreferentialType.Exchange:
        return 'Exchange'
    }
  }

  get couponStatus() {
    if (this.success) return { text: '', style: '' }

    return this.data.stockQty === 0 ? { text: '已发完', style: 'black' } : { text: '发放失败，已达领取上限', style: 'red' }
  }

  handleClickUseInfo() {
    // @ts-ignore
    this.$refs['coupon-btn-comp']?.clickUseDescHandler()
  }

  @Emit('to-use')
  handleToUse() {}

  @Emit('receive')
  handleReceive() {}

  @Emit('exchange')
  handleExchange() {}

  created() {
    this.formatCouponInfo = formatCouponInfo(this.data, this.success ? 'coupon' : 'activity')
  }
}

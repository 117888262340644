





















import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import Account from './views/account/index.vue'
import Notice from './views/notice/index.vue'
import Auth from './views/auth/index.vue'
import Condition from './views/condition/index.vue'
import Abandon from './views/abandon/index.vue'
import { EnumLogoutType } from './enum/index'
import { ICheckUserInfoResp } from '../index.vue'

@Observer
@Component({
  components: {
    Teleport,
    Auth,
    Condition,
    Notice,
    Account,
    Abandon
  }
})
export default class PageView extends Vue {
  @Prop({}) show: boolean
  @Prop() checkUserInfo: ICheckUserInfoResp

  get PageStore() {
    return this.$store.$storeUser
  }
  get EnumLogoutType() {
    return EnumLogoutType
  }

  isView(title: 'notice' | 'condition' | 'auth' | 'account' | 'abandon') {
    return title === this.PageStore.logoutModalTitle
  }

  @Emit('onHide')
  hide() {}

  clickModel() {}

  @Watch('show')
  changeShow(value: boolean) {
    if (value) {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.height = 'auto'
      document.body.style.overflow = 'auto'
    }
  }
}

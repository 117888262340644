


































































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'
import { BaseCouponItem } from '@xt/client/types/coupon'
import { Loop } from '@xt/client/utils/loop'
import OrderCouponGift from '@xt/www/components/orderCouponGift/index.vue'
import { EnumCouponStatus } from '@xt/client/enums'

@Observer
@Component({
  // name: "PageStationery",
  // 每次进入页面都会调用
  async fetch(ctx) {
    // ctx.store.$storeStationery.onGetTypelist();
    // 更新用户信息 获取桐板儿
    ctx.store.$storeUser.onUpdatingUserInfo()
  },
  async asyncData(ctx) {
    await ctx.store.$storeOrder.Details.onLoading({ orderNo: ctx.query.no })
  },
  components: { LazyImg, OrderCouponGift }
})
export default class PageView extends Vue {
  pollingLoop: null | Loop
  giftData: null | { failList: Array<BaseCouponItem>; successList: Array<BaseCouponItem> } = null

  /**
   * 支付
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get PageStore() {
    return this.$store.$storeOrder.Details
  }
  get orderNo() {
    return this.$route.query.no
  }
  get isSexStage() {
    return this.PageStore.dataSource.productId === 534
  }
  groupImg: any = []
  deadline = this.moment().add(5, 'seconds').valueOf()
  onFinish() {
    if (this.$store.$global.dev) {
      return this.$msg('本地不跳转')
    }
    this.$router.replace({
      name: 'course-id',
      params: { id: this.PageStore.dataSource.courseId }
    })
  }
  toMyOrder() {
    this.$router.push({
      name: 'my-order'
    })

    // 6.30
    // this.$store.$storeUser.onGetUniformGift()
  }
  toMyClass() {
    this.$router.push({
      name: 'my'
    })

    // 6.30
    // this.$store.$storeUser.onGetUniformGift()
  }
  groupStatus = 0
  toMyGroup() {
    let groupNo = this.PageStore.dataSource.groupNo
    let productId = this.PageStore.dataSource.productId
    window.location.href = `/groupbuy/${groupNo}`
  }

  onReceive(data: { id: number; youzanDetail: any }) {
    const cloneList = [...this.giftData.successList].map(v => {
      if (v.id === data.id) {
        lodash.set(v, 'wenfangMemberHeader', data.youzanDetail.avatar)
        lodash.set(v, 'wenfangMemberNickname', data.youzanDetail.userNickname)
        lodash.set(v, 'wenfangPhoneNum', data.youzanDetail.userPhone)
        lodash.set(v, 'wenfangReceived', true)
      }
      return v
    })
    this.giftData = Object.assign(this.giftData, { successList: cloneList })
  }

  onExchange(id: number) {
    const cloneList = [...this.giftData.successList].map(v => {
      if (v.id === id) {
        lodash.set(v, 'status', EnumCouponStatus.Used)
      }
      return v
    })
    this.giftData = Object.assign(this.giftData, { successList: cloneList })
  }

  async mounted() {
    this.$store.$storeUser.onUpdatingUserInfo()

    // 轮询状态
    let that = this
    this.pollingLoop = new Loop(
      async () => {
        try {
          const data = await this.$store.$coupon.fetchOrderSuccessCouponGift(this.orderNo as string)

          return data
        } catch {
          return null
        }
      },
      {
        pollingErrorRetryCount: 8,
        pollingInterval: 1 * 1000,
        checkResult(result) {
          if (!result) return Loop.CheckResult.Retry

          if (result.completePayment === true) {
            that.giftData = { successList: result.successList, failList: result.failList }
            return Loop.CheckResult.Stop
          }

          return Loop.CheckResult.Retry
        }
      }
    )

    try {
      if (!this.PageStore.dataSource.showAssistantUrlFlag) return
      // 获取团购加群二维码
      let res: any = await this.$store.$storeOrder.addGroupImg({ productId: this.PageStore.dataSource.productId })
      this.groupImg = res
    } catch (error) {}
  }
  updated() {}
  destroyed() {
    if (this.pollingLoop) {
      this.pollingLoop.stopPolling()
    }
  }
}






















































































































import { Component, Prop, Vue, Ref, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { FormModel } from 'ant-design-vue'
import lodash from 'lodash'

@Observer
@Component
export default class PageView extends Vue {
  @Prop({ default: true }) validDevice // 发送验证码是否需要验证设备
  @Prop({ default: true }) phone // 手机/邮箱 默认手机
  @Prop({ default: '' }) defaultValue // 默认值
  @Prop({ default: false }) password // 密码验证 默认false
  @Prop({ default: false }) isLabel // true展示为label
  @Prop({ default: '手机号' }) label // 设备描述
  @Prop({ default: '绑定' }) btnText // 按钮文字
  @Prop({ default: '请输入验证码' }) inputInnerText //输入框提示文字
  @Prop({ default: true }) isNeed // 是否需要将短信验证码以及密码作为修改校验条件
  @Prop({ default: '' }) confirmCode // 验证码
  @Prop({ default: false }) isChangeStyle // 是否需要改变底部按钮的距离
  @Prop({ default: false }) isFirstTrigger // 是否需要一进入就触发，发送验证码
  @Prop({ default: false }) isInput // 是否需要改变密码时是否输入了新旧密码
  @Prop({ default: false }) isInputChange //校验密码变化
  @Prop({ default: false }) submitLoading: boolean // 按钮loading
  @Prop({
    default: () => {
      return {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      }
    }
  })
  layout // form布局
  @Prop({
    default: () => {
      return { span: 24 }
    }
  })
  btnCol // btn布局
  @Prop({ default: () => {} }) rules
  @Prop({ default: '' }) newPassword
  @Prop({ default: '' }) confirmNewPassword
  @Prop({ default: false }) retrieve
  @Ref('ruleForm')
  ruleForm: FormModel
  @Ref('codeRef') codeRef
  @Ref('passwordRef') passwordRef
  passwordEye: boolean = false

  get PageStore() {
    return this.$store.$storeUser
  }
  get placeholder() {
    return `请输入${this.label}`
  }
  get disabled() {
    if (this.retrieve) {
      if (this.password) {
        return !this.model.device || !this.model.password || !this.newPassword || !this.confirmNewPassword
      } else {
        return !this.model.device || !this.model.confirmCode || !this.newPassword || !this.confirmNewPassword
      }
    } else {
      if (this.isInput) {
        return !this.isInputChange
      }
      if (this.btnText === '下一步' || this.btnText === '绑定') {
        return this.model.confirmCode.length < 4
      }
      if (this.password) {
        return !this.model.device || !this.model.password
      } else {
        return !this.model.device || !this.model.confirmCode
      }
    }
  }
  get codeDisabled() {
    return !this.model.device
  }

  deadline = 60
  isStartCountDown = false

  model = {
    device: '',
    confirmCode: '',
    password: ''
  }
  formRules = {}
  @Watch('defaultValue', { immediate: true })
  onChange(newVal) {
    this.onReset()
    lodash.set(this.model, 'device', newVal)
  }
  @Watch('phone')
  onChangePhone(newVal) {
    this.onReset()
  }
  @Watch('password')
  onChangePassword(newVal) {
    this.onReset()
  }
  @Watch('validDevice')
  onChangeVaild(newVal) {
    this.onReset()
  }
  created() {
    this.isFirstTrigger && this.onSendSms()
  }
  mounted() {
    this.model.confirmCode = this.confirmCode
    if (this.phone && !this.defaultValue && this.$store.$global.dev) {
      this.model.device = '16666034724'
      this.model.password = 'abcd-1234'
    }
  }

  numberInput(target) {
    target.value = target.value.replace(/[^\d]/g, '')
  }
  passswordInput(target) {
    target.value = target.value.replaceAll(' ', '')
  }
  //email过滤
  emailInput(target) {
    target.value = target.value.replace(/[\r\n\t\f\v\s]/g, '')
  }
  onReset() {
    this.formRules = lodash.merge(getRules.call(this), this.rules)
    this.ruleForm && this.ruleForm.resetFields()
    this.toggleCountDown(false)
  }
  // 提交
  onSubmit() {
    this.ruleForm.validate((vaild, errList) => {
      if (vaild) {
        if (this.btnText === '下一步' || this.btnText === '绑定') {
          this.$emit('submit', { disabled: this.disabled, device: this.model.device, confirmCode: this.model.confirmCode })
        } else {
          this.$emit('submit', {
            device: this.model.device,
            verifyCode: this.model.confirmCode,
            password: this.model.password
          })
        }
      } else {
        let keys = lodash.keys(errList)
        let error = lodash.get(errList, [lodash.head(keys), 0, 'message'], '校验失败')
        return this.$msg(error)
      }
    })
  }
  sendSmsLock: boolean = false
  /** 发送验证码 */
  async onSendSms() {
    if (this.sendSmsLock) return
    this.sendSmsLock = true
    if (this.validDevice) {
      this.ruleForm.validateField('device', async error => {
        if (error) {
          this.$msg(error)
          this.sendSmsLock = false
          return
        }
        await this.onGetCode()
      })
    } else {
      await this.onGetCode()
    }
  }
  /**
   * 调用父组件的获取验证码
   */
  async onGetCode() {
    await this.$emit(
      'sendSms',
      this.model,
      () => {
        this.toggleCountDown(true)
      },
      () => {
        this.sendSmsLock = false
      }
    )
  }

  /**
   * 切换倒计时开始/结束
   */
  toggleCountDown(loading: boolean = !this.isStartCountDown) {
    if (loading) {
      this.deadline = Date.now() + 1000 * 60
    }
    this.isStartCountDown = loading
  }
}
function getRules() {
  const validatePhone = async (rule, value, callback) => {
    if (value == '') {
      return callback(new Error('请输入手机号'))
    }
    if (!this.$Regulars.mobilePhone.test(value)) {
      return callback(new Error('请输入正确的手机号'))
    }
    // if (value == this.oldValue) {
    //   return callback(new Error("新手机号不能与原手机号相同"));
    // }
    return callback()
  }

  const validateEmail = async (rule, value, callback) => {
    if (value == '') {
      return callback(new Error('请输入邮箱'))
    }
    if (!this.$Regulars.email.test(value)) {
      return callback(new Error('请输入正确的邮箱'))
    }
    // if (value == this.oldValue) {
    //   return callback(new Error("新邮箱不能与原邮箱相同"));
    // }
    return callback()
  }
  return {
    device: [
      {
        validator: this.phone ? validatePhone : validateEmail,
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }
    ],
    confirmCode: [
      {
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      },
      { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
    ]
  }
}
